<template>
  <div
    v-click-outside="hideDropdown"
    class="v-select"
    :class="{ onlyDropdown }"
    @click="toggleDropdown"
  >
    <div
      v-if="selected && !onlyDropdown"
      class="v-select__selected"
    >
      <div class="v-select__clear" @click.stop="clear">
        <VIcon name="IconClose"></VIcon>
      </div>
      <div
        v-if="isProject"
        :style="{ background: selected.color }"
        class="v-select__dot"
      ></div>
      <div
        v-else-if="withImg && selected.img || selected.logo"
        class="v-select__img"
      >
        <img :src="selected.img || selected.logo"/>
      </div>
      <div class="v-select__icon" v-else-if="selected.icon">
        <VIcon :name="selected.icon"></VIcon>
      </div>

      <div
        v-else-if="withColorLabel && selected.color"
        class="v-select__color"
        :style="{ background: selected.color }"
      ></div>

      <div
        v-else-if="withWord && selected.color && !withColorLabel"
        class="v-select__word"
        :style="{ background: selected.color }"
      >
        {{ nameToLetter(selected.name || selected.email) }}
      </div>

      <div class="v-select__name">{{ selected.name || selected.email }}</div>
    </div>

    <div
      v-else-if="!selected && !onlyDropdown"
      class="v-select__placeholder"
    >
      <div v-if="placeholderIcon" class="v-select__icon">
        <VIcon :name="placeholderIcon"></VIcon>
      </div>
      <div class="v-select__placeholder-text">{{ placeholderText }}</div>
    </div>

    <div v-if="openedDropdown || onlyDropdown" class="v-select__dropdown">
      <div v-show="enableSearch" class="v-select__search">
        <VIcon name="Search"></VIcon>
        <input
          type="text"
          :placeholder="$t('search')"
          v-model="search"
          tabindex="-1"
          ref="input"
          @click.stop
          @keydown.down.prevent.stop="selectOption(0)"
        />
      </div>
      <VDropdown
        :options="foundOptions"
        :width="165"
        :selected="selected"
        :withWord="withWord"
        :withColorLabel="withColorLabel"
        ref="dropdown"
        @select="select"
      />
    </div>
  </div>
</template>

<script>
  import VDropdown from '@/components/ui/VDropdown/VDropdown'
  import VIcon from '@/components/ui/VIcon'

  export default {
    name: 'VSelect',
    components: {
      VDropdown,
      VIcon,
    },

    props: {
      onlyDropdown: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: Object,
        default: null,
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: null,
      },
      width: {
        type: Number,
        default: null,
      },
      withImg: {
        type: Boolean,
        default: null,
      },
      isProject: {
        type: Boolean,
        default: false,
      },
      withColorLabel: {
        type: Boolean,
        default: false,
      },
      withWord: {
        type: Boolean,
        default: false,
      },
      placeholderText: {
        type: String,
        default: '',
      },
      placeholderIcon: {
        type: String,
        default: '',
      },
      enableSearch: {
        type: Boolean,
        default: false,
      },
      searchKeys: {
        type: Array,
        default: () => [
          'name',
          'title',
        ],
      },
    },
    data: () => ({
      openedDropdown: false,
      search: '',
    }),
    computed: {
      foundOptions() {
        return this.options.filter(option => this.searchKeys.some(key =>
          String(option[key]).toLowerCase().includes(
            this.search.toLowerCase()
          )
        ));
      },
    },
    watch: {
      search() {
        this.selectOption(null)
      },
      openedDropdown() {
        this.$nextTick(() => {
          if (!this.openedDropdown) {
            return;
          }

          if (this.autofocus && this.enableSearch) {
            this.$refs.input.focus()
          } else {
            this.selectOption(0)
          }
        })
      }
    },

    methods: {
      select(id) {
        this.$emit('select', id)
        this.hideDropdown()
        this.search = ''
      },
      hideDropdown() {
        this.openedDropdown = false
      },
      toggleDropdown() {
        this.openedDropdown = !this.openedDropdown
      },
      selectOption(index) {
        this.$refs.dropdown.setTarget(index)
      },
      clear() {
        this.$emit('clear')
      },
    },

    mounted() {
      if (!this.onlyDropdown || !this.autofocus) {
        return
      }

      if (this.enableSearch) {
        this.$refs.input.focus()
      } else {
        this.selectOption(0)
      }
    },
  }
</script>

<style lang="scss">
  .dark-theme {
    .v-select {
      border: 1px solid $darkThemeBgThree;
      background: $darkThemeBgTwo;

      &__clear {
        svg {
          path {
            &:nth-child(1) {
              fill: $darkThemeLightBlue
            }

            &:nth-child(2) {
              fill: $darkThemeBlue
            }
          }
        }
      }

      &__placeholder {
        color: $darkThemeTextColor;
      }

      &__icon {
        svg {
          path {
            &:nth-child(1) {
              fill: $darkThemeIconLightGrey
            }

            &:nth-child(2) {
              fill: $darkThemeIconGrey
            }
          }
        }
      }

      &__selected {
        &:hover {
          .v-select__clear {
            background: rgba($darkThemeBgOne, .8);
          }
        }

        .v-select__icon {
          svg {
            path {
              &:nth-child(1) {
                fill: $darkThemeLightBlue
              }

              &:nth-child(2) {
                fill: $darkThemeBlue
              }
            }
          }
        }
      }

      &__img {
        border: 1px solid $darkThemeBgThree;
      }

      &__search {
        border-bottom: 1px solid $darkThemeBgThree;

        svg {
          path {
            &:nth-child(1) {
              fill: $darkThemeIconLightGrey
            }

            &:nth-child(2) {
              fill: $darkThemeIconGrey
            }
          }
        }

        input {
        }

        &_focused {
          svg {
            path {
              &:nth-child(1) {
                fill: $darkThemeLightBlue
              }

              &:nth-child(2) {
                fill: $darkThemeBlue
              }
            }
          }

          input {
            background: $darkThemeBgOne;
          }
        }
      }

      &__dropdown {
        background: $darkThemeBgTwo;
        box-shadow: 0 6px 13px 0 rgba(25, 25, 25, 0.48);
      }
    }
  }

  .v-select {
    border: 1px solid $darkThemeTextLight;
    border-radius: 4px;
    user-select: none;
    position: relative;
    font-size: 12px;
    $root: &;

    &.onlyDropdown {
      border: none;

      #{$root} {
        &__dropdown {
          position: relative;
        }
      }
    }

    &__clear {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      cursor: pointer;
      z-index: 2;

      &:hover {
        svg {
          opacity: 1 !important;
        }
      }

      svg {
        display: block;
        width: 10px;
        height: 10px;
        opacity: 0;
      }
    }

    &__selected,
    &__placeholder {
      padding: 0 10px;
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
    }

    &__placeholder {
      color: #c1c1c1;
      font-weight: 300;
    }

    &__icon {
      flex: none;
      margin-right: 10px;

      svg {
        display: block;
        width: 18px;
        height: 18px;

        path {
          &:nth-child(1) {
            fill: $lightGrey
          }

          &:nth-child(2) {
            fill: $grey
          }
        }
      }
    }

    &__selected {
      position: relative;

      &:hover {
        #{$root} {
          &__clear {
            background: rgba(#fff, .8);

            svg {
              opacity: .5;
            }
          }
        }
      }

      #{$root} {
        &__icon {
          svg {
            path {
              &:nth-child(1) {
                fill: $lightBlue
              }

              &:nth-child(2) {
                fill: $blue
              }
            }
          }
        }
      }
    }

    &__color {
      border-radius: 50%;
      flex: none;
      width: 16px;
      height: 16px;
      margin-right: 11px;
      margin-left: 1px;
    }

    &__img {
      border-radius: 50%;
      flex: none;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: -1px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border: 1px solid $darkThemeTextLight;

      img {
        display: block;
        height: 100%;
      }
    }

    &__search {
      width: 100%;
      position: relative;
      border-bottom: 1px solid $borderGrey;

      svg {
        display: block;
        position: absolute;
        left: 10px;
        width: 18px;
        height: 19px;
        top: 50%;
        margin-top: -10.5px;
        pointer-events: none;

        path {
          &:nth-child(1) {
            fill: $lightGrey
          }

          &:nth-child(2) {
            fill: $grey
          }
        }
      }

      input {
        display: block;
        outline: none;
        width: 100%;
        height: 38px;
        font-size: rem(12px);
        outline: none;
        border: none;
        font-weight: 300;
        padding: 0 11px 0 38px;
        background: transparent;
        box-shadow: none !important;
        border: none !important;
      }

      &_focused {
        svg {
          path {
            &:nth-child(1) {
              fill: $lightBlue
            }

            &:nth-child(2) {
              fill: $blue
            }
          }
        }

        input {
          background: #fff;
        }
      }
    }

    &__dropdown {
      position: absolute;
      left: 0;
      top: 100%;
      margin-top: 5px;
      z-index: 5;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 4px 13px rgba(48, 48, 48, 0.1);
      width: 100%;
      min-width: 180px;

      .AppDropdown {
        box-shadow: none;
        width: 100% !important;
      }
    }
  }
</style>
